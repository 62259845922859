<template>
    <div>
        <app-heading-3 class="mb-1">Add Billing Information</app-heading-3>
        <validation-observer ref="paymentForm" >
            <div class="bg-white p-24">
                <b-alert class="px-2 py-1" v-model="errorMessage.length" variant="danger">
                    {{ errorMessage }}
                </b-alert>
                <!-- <div>
                    <app-text-input label="Email Address" v-model="billing_details.email" placeholder="john.doe@gmail.com"/>
                </div> -->
                <div>
                    <app-select-box label="Country" v-model="billing_details.country" disabled :options="options" @onChange="setStates()"></app-select-box>
                </div>
                <div>
                    <validation-provider #default="{ errors }" name="State" vid="state" rules="required">
                        <app-select-box label="State" v-model="billing_details.state"  :errors="errors" :options="states"></app-select-box>
                    </validation-provider>
                </div>
                <div>
                    <validation-provider #default="{ errors }" name="Address line 1" vid="address_line_1" rules="required">
                        <app-text-input label="Address"  v-model="billing_details.line1" placeholder="Address Line 1" :errors="errors"/>
                    </validation-provider>
                </div>
                <div>
                    <app-text-input v-model="billing_details.line2" placeholder="Address Line 2"/>
                </div>
                <div>
                    <validation-provider #default="{ errors }" name="City" vid="city" rules="required">
                        <app-text-input label="City" v-model="billing_details.city" placeholder="City" :errors="errors"/>
                    </validation-provider>
                </div>
                <div>
                    <validation-provider #default="{ errors }" name="Zip Code" vid="zip_code" rules="required">
                        <app-text-input label="Zip Code" v-model="billing_details.postal_code" placeholder="63854" :errors="errors" @onKeydown="isZipCode"/>
                    </validation-provider>
                </div>

                <!-- <div class="mb-1">
                    <app-text-input v-model="billing_details.zip" placeholder="Zip"/>
                </div> -->
                <div class="d-flex">
                    <app-simple-button variant="outline-primary" class="mr-1" @click="$emit('prev')">Back</app-simple-button>
                    <app-simple-button variant="primary" :loading="loading" @click="validatePaymentData()">Save</app-simple-button>
                </div>
            </div>
        </validation-observer>
    </div>

</template>
<script>
import {BBadge, BAlert} from 'bootstrap-vue'
import AppSimpleButton from '@/components/app-components/AppSimpleButton.vue'
import AppTextInput from '@/components/app-components/AppTextInput.vue'
import AppSelectBox from "@/components/app-components/AppSelectBox.vue"
import AppHeading3 from "@/components/app-components/AppHeading3.vue"
import { Country, State, City }  from 'country-state-city';
import { ValidationProvider, ValidationObserver} from 'vee-validate'
import { required} from '@validations'
export default{
    components:{
        BBadge,
        BAlert,
        AppSimpleButton,
        AppHeading3,
        AppTextInput,
        AppSelectBox,
        ValidationProvider, 
        ValidationObserver
    },
    data(){
        return{
            billing_details:{
                country:'US',
                line1:'',
                line2:'',
                city:'',
                state:'',
                postal_code:'',
                // zip:'',
            },
            options: [],
            states: [],
            loading:false,
            required,
            errorMessage:'',
            cardNumberError:'',
            expError:'',
            required
        }
    },
    mounted() {
        this.setAllCountries(Country.getAllCountries())
        this.setStates()
    },
    methods: {
        setAllCountries(countries) {
            this.options = countries.map(country => ({
                value: country.isoCode,
                text: country.name
            }))
            this.options.unshift({
                value: null,
                text: 'Country'
            })
        },
        setStates(){
            const states = State.getStatesOfCountry(this.billing_details.country)
            this.states = states.map(state=> ({
                value: state.isoCode,
                text: state.name
            }))
            this.states.unshift({
                value: '',
                text: 'State'
            })
        },
        getPaymentMethod(){
            this.loading=true;
            this.newPayment = true;
            get_payment_method().then(resp=>{
                this.loading=false;
                this.newPayment = false;
                this.cardData.payment_method_id=resp.id;
                this.cardData.last4=resp.card.last4;
                this.cardData.exp_month=resp.card.exp_month;
                this.cardData.exp_year=resp.card.exp_year;
                this.cardData.brand=resp.card.brand;
            }).catch(err=>{
                this.loading=false;
                this.$bvToast.toast(err.message, {
                        title: err.error,
                        variant: 'danger',
                        solid: true,
                    }) 
            })
        },
        validatePaymentData(){
            this.$refs.paymentForm.validate().then(success=>{
                if(!success) {
                    window.scrollTo(0,0);
                    this.errorMessage = 'Please fill all the required fields & enter valid data'
                    return
                }
                this.$emit('next',this.billing_details)
            })
        },
        isZipCode(e){
            if(![8,9,37,39,46].includes(e.keyCode)){
                let char = String.fromCharCode(e.keyCode); // Get the character
                if(/[0-9]/.test(char)&& this.billing_details.postal_code.length<7) return true;
                // if(this.user_data.phone_number.length<11) return true; // Match with regex 
                else e.preventDefault(); 
            }
        },
        
    },
}
</script>
<style scoped>
.p-24{
    padding: 24px;
    border-radius: 6px;
}
.sup{
    font-size: 15px;
    font-weight: 400;
    line-height: 22px;
    position: relative;
    bottom: 15px;
}
.price-text{
    font-size: 36px;
    font-weight: 600;
    line-height: 52px;
}
.text-simple{
    font-size: 15px;
    font-weight: 400;
    line-height: 22px;
    color: #4B465C;
}
</style>